---
title: "Getting Started"
description: "Getting Started"
created: 2025-01-01
updated: 2025-01-01
toc_depth: 0
---
<script lang="ts">
    import { selected_org } from "$lib/stores/selected_org"
    import { Important } from "$lib/components/alerts"
    import { Tip } from "$lib/components/alerts"
    import { CopyButton } from 'svelte-ux';
</script>

# Getting Started

*Welcome!* Transform your deployment workflow with CentralCI and Concourse - let's build your first pipeline together!

<Important>
  This tutorial assumes you understand what Linux containers are and how to work with them. If you know what a
  Dockerfile is and how to make your own then you're probably good to jump into this tutorial. If you're not familiar with
  Linux containers then you may want to get started with Docker first before diving into this tutorial.

  It will also help if you know how to read YAML. We have a quick
  [Intro to YAML](https://concourse-ci.org/config-basics.html#intro-to-yaml) if you're not familiar with the syntax.
</Important>

[Concourse CI](https://concourse-ci.org/) is a powerful open source CI/CD platform that liberates your pipelines through declarative configs and 100+
integrations. CentralCI runs a Concourse cluster for you at https://**{$selected_org.name}.centralci.com**.

### 1. Login with the `fly` CLI

To interact with Concourse, install the `fly` CLI tool ([MacOS](https://{$selected_org.name}.centralci.com/api/v1/cli?arch=amd64&platform=darwin), [Windows](https://{$selected_org.name}.centralci.com/api/v1/cli?arch=amd64&platform=windows) or [Linux](https://{$selected_org.name}.centralci.com/api/v1/cli?arch=amd64&platform=linux)) and log in.
`fly` is Concourse's command-line interface (CLI) tool.

<Tip>
fly -t ci login -c https://{$selected_org.name}.centralci.com <CopyButton color="primary"  size="sm" class="p-2" value="fly -t ci login -c https://{$selected_org.name}.centralci.com"></CopyButton>
</Tip>

### 2. Deploy a Hello World pipeline

Create a new file called `pipeline.yml` <CopyButton color="primary"  size="sm" class="p-2" value="pipeline.yml"></CopyButton>  (or consult `fly -h`):

```yaml
---
jobs:
- name: hello-world-job
  plan:
  - task: hello-world-task
    config:
      # Tells Concourse which type of worker this task should run on
      platform: linux
      # We'll use this container image for our task - more on these container resources later
      image_resource:
        type: registry-image
        source:
          repository: busybox # images are pulled from docker hub by default
      # The command Concourse will run inside the container
      run:
        path: echo
        args: ["Hello world!"]
```

Deploy your pipeline:

```shell
fly -t centralci set-pipeline -p hello -c pipeline.yml
```

Unpause your pipeline:

```shell
fly -t centralci unpause-pipeline -p hello
```

### 3. Key Concepts

- **Pipelines**: Your entire workflow defined in YAML
- **Jobs**: Units of work combining tasks and resources
- **Tasks**: The actual commands to run
- **Resources**: External things your pipeline interacts with (git repos, S3 buckets, etc.)

### 4. Next Steps

- Watch your pipeline run at [https://{$selected_org.name}.centralci.com/teams/main/pipelines/hello](https://{$selected_org.name}.centralci.com/teams/main/pipelines/hello)
- Add more tasks to your pipeline
- Try different resource types (docker images, S3, etc.)
- Set up pipeline triggers for automated builds