<script lang="ts">
  import { onMount } from "svelte"
  import { Badge, Indicator, Popover } from "flowbite-svelte"

  let { as_badge }: { as_badge: boolean } = $props()

  let serviceStatus = $state(
    "fetching" as "fetching" | "ok" | "degraded" | "unknown",
  )

  onMount(async () => {
    const res = await fetch("https://centralcistatus.com/api/v1/summary")
    const data = await res.json()

    if (res.ok) {
      if (data.ongoing_incidents.length === 0) serviceStatus = "ok"
      else serviceStatus = "degraded"
    } else {
      serviceStatus = "unknown"
    }
  })
</script>

{#snippet linkStatusIndicator(color: "yellow" | "green" | "blue", text: string)}
  <a
    href="https://centralcistatus.com/"
    target="_blank"
    class="flex flex-row gap-1 items-center"
  >
    <Indicator {color} size="xs" class="me-1" />
    <span>{text}</span>
  </a>
{/snippet}

{#snippet badgeStatusIndicator(
  color: "yellow" | "green" | "blue",
  status: string,
  title: string,
)}
  <Badge {color} rounded class="px-2 py-1.5">
    <Indicator {color} size="xs" class="me-1" />
    <a href="https://centralcistatus.com/" target="_blank">{status}</a>
  </Badge>
  <Popover class="w-64 text-sm font-light" title="Service Status">
    {title}
  </Popover>
{/snippet}

{#snippet statusIndicator(
  color: "yellow" | "green" | "blue",
  status: string,
  title: string,
)}
  {#if as_badge}
    {@render badgeStatusIndicator(color, status, title)}
  {:else}
    {@render linkStatusIndicator(color, title)}
  {/if}
{/snippet}

{#if serviceStatus === "fetching"}
  {@render statusIndicator("blue", "Fetching...", "Fetching status")}
{:else if serviceStatus === "ok"}
  {@render statusIndicator("green", "ALL OK", "All Systems Operational ✅")}
{:else if serviceStatus === "degraded"}
  {@render statusIndicator("yellow", "Degraded", "System is degraded")}
{:else}
  {@render statusIndicator("yellow", "Unknown", "incident.io uncontactable")}
{/if}
