<script lang="ts">
  import type { Icon as LucideIcon } from "lucide-svelte"
  import type { Snippet, Component } from "svelte"

  type Props = {
    Icon: typeof LucideIcon
    title: string
    children: Snippet
    color: string
    customBgColour?: string
    customEdgeColor?: string
    customTextColor?: string
  }
  let {
    Icon,
    title,
    children,
    color = "red-600",
    customBgColour = undefined,
    customEdgeColor = undefined,
    customTextColor = undefined,
  }: Props = $props()

  let bgColor = customBgColour ?? color
  let edgeColor = customEdgeColor ?? color
  let textColor = customTextColor ?? color
</script>

<div
  class="flex flex-col border-l-4 pl-5 py-1 my-1 bg-{bgColor} bg-opacity-5 border-{edgeColor}"
>
  <div class="text-{textColor} font-bold flex flex-row items-center mb-1">
    <Icon class="mr-2"></Icon>
    {title}
  </div>
  <div class="w-full">
    {@render children()}
  </div>
</div>
