<script lang="ts">
  import { type RichUser } from "$lib/load_helpers"

  import {
    Avatar,
    Dropdown,
    DropdownItem,
    DropdownHeader,
    DropdownDivider,
    A,
    Kbd,
  } from "flowbite-svelte"

  import { SquareUserRound, Layers, LogOut } from "lucide-svelte"

  let { rich_user }: { rich_user: RichUser } = $props()
</script>

<div id="avatar-surround" class="p-0.5 ml-3">
  <Avatar size="none" class="w-10 h-10" src={rich_user.avatar_url} />
</div>

<Dropdown
  placement="bottom-start"
  triggeredBy="#avatar-surround"
  class="overflow-y-auto py-1 drop-shadow-xl"
>
  <DropdownHeader>
    <span class="block font-bold">{rich_user.full_name}</span>
    <span class="block text-sm">{rich_user.email}</span>
  </DropdownHeader>
  <DropdownItem href="/account/settings" class="flex flex-row gap-2">
    <SquareUserRound size="20" />Account Settings
  </DropdownItem>
  <DropdownItem href="/account/subscriptions" class="flex flex-row gap-2">
    <Layers size="20" />Manage Subscriptions
  </DropdownItem>
  <DropdownDivider />
  <DropdownItem href="/sign_out" class="flex flex-row gap-2">
    <LogOut size="20" /> Log Out
  </DropdownItem>
</Dropdown>
