<script lang="ts">
  import { TriangleAlert } from "lucide-svelte"
  import Alertbox from "./alertbox.svelte"
  let { children } = $props()

  let color = "yellow-400"
</script>

<Alertbox Icon={TriangleAlert} title="Warning" color="yellow-400" {children}
></Alertbox>
