import { persisted, type Persisted } from 'svelte-persisted-store'

export type Org = {
  uuid: string;
  name: string;
  avatar_url: string | null;
}

export const null_org: Org = { uuid: "", name: "No Org", avatar_url: null}

export const selected_org: Persisted<Org> = persisted("selected_org", null_org)

// selected_org.subscribe(x => {
//   console.log("selected_org set to: ", x)
// })

