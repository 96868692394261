export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62')
];

export const server_loads = [0,2,3,4,5,7,8];

export const dictionary = {
		"/(marketing)": [49,[14]],
		"/(marketing)/about": [50,[14]],
		"/(authorised)/account": [17,[2,3]],
		"/(authorised)/account/api": [18,[2,3]],
		"/(authorised)/account/settings": [19,[2,3,4]],
		"/(authorised)/account/subscriptions": [~20,[2,3]],
		"/(marketing)/blog": [51,[14,15]],
		"/(marketing)/blog/posts/[slug=blogpost]": [52,[14,15]],
		"/(marketing)/changelog": [53,[14]],
		"/(marketing)/contact_us": [54,[14]],
		"/(docs)/docs": [40,[9]],
		"/(docs)/docs/[...slug=doc]": [41,[9]],
		"/(marketing)/features": [55,[14]],
		"/(authorised)/github/app_setup": [21,[2,5]],
		"/(login)/login": [~42,[10,11]],
		"/(login)/login/authenticate": [43,[10,11,12]],
		"/(login)/login/current_password_error": [44,[10,11]],
		"/(login)/login/forgot_password": [45,[10,11]],
		"/(login)/login/oauth": [46,[10,11]],
		"/(login)/login/register": [47,[10,11,13]],
		"/(oauth-provider)/oauth2/auth": [~61],
		"/(authorised)/onboarding/select_plan": [22,[2,6]],
		"/(authorised)/onboarding/subscriptions/confirm/[planid]/[period]": [~23,[2,6]],
		"/(authorised)/onboarding/subscriptions/do_assignment": [~24,[2,6]],
		"/(authorised)/onboarding/subscriptions/do_subscribe/[planid]/[period]": [~25,[2,6]],
		"/(authorised)/onboarding/subscriptions/on_succeeded": [~26,[2,6]],
		"/(authorised)/org/confirm_org_deleted": [27,[2,7]],
		"/(authorised)/org/danger_zone": [28,[2,7]],
		"/(authorised)/org/danger_zone/delete_org": [~29,[2,7]],
		"/(authorised)/org/overview": [30,[2,7]],
		"/(authorised)/org/settings/auditing": [31,[2,7]],
		"/(authorised)/org/settings/checks": [32,[2,7]],
		"/(authorised)/org/settings/general": [33,[2,7]],
		"/(authorised)/org/settings/logging": [34,[2,7]],
		"/(authorised)/org/users": [35,[2,7]],
		"/(marketing)/partners": [56,[14]],
		"/(marketing)/pricing": [57,[14]],
		"/(marketing)/sales": [58,[14]],
		"/(marketing)/security": [59,[14]],
		"/(login)/sign_out": [48,[10]],
		"/(authorised)/submit_feedback": [36,[2]],
		"/(marketing)/support": [60,[14]],
		"/(demos)/testpages/cobe": [39],
		"/(authorised)/testpages/gh_token_refresh": [37,[2,8]],
		"/(authorised)/testpages/logevents/view": [38,[2]],
		"/(policies)/[slug=policydoc]": [62,[16]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';