---
title: "Tutorial"
description: "Tutorial"
created: 2025-01-01
updated: 2025-01-01
---
<script lang="ts">
    import { selected_org } from "$lib/stores/selected_org"
</script>
# Tutorial

Your organisation is "{$selected_org.name}"