<script lang="ts">
  import { type RichUser } from "$lib/load_helpers"

  import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownDivider
  } from "flowbite-svelte"

  import {
    Users,
    CircleHelp,
    LifeBuoy,
    Newspaper,
    LayoutList,
    SquareArrowOutUpRight,
    CalendarDays
  } from "lucide-svelte"

  import SubmitFeedbackModal from "./submit-feedback-modal.svelte"

  import Concourse from "./icons/concourse.svelte"

  let { rich_user }: { rich_user: RichUser | null | undefined } = $props()

  let infoDropdownOpen = $state(false)
  let feedbackModalOpen = $state(false)
</script>

<Button outline color="light" class="!p-2 ml-4" id="support-menu">
  <CircleHelp class="w-4 h-4" />
</Button>

<Dropdown
  placement="bottom-end"
  bind:open={infoDropdownOpen}
  triggeredBy="#support-menu"
  class="overflow-y-auto py-1 drop-shadow-xl"
>
  {#if rich_user}
    <DropdownItem
      onclick={() => {
        infoDropdownOpen = false
        feedbackModalOpen = true
      }}
      class="flex flex-row gap-2"
    >
      <Users size="20" class="display-inline" />
      Send Feedback
    </DropdownItem>

    <DropdownItem class="flex flex-row gap-2">
      <LifeBuoy size="20" />
      Support
    </DropdownItem>

    <DropdownDivider />
  {/if}

  <DropdownItem class="flex flex-row gap-2">
    <Newspaper size="20" />
    <a class="grow" href="/docs">Documentation</a>
  </DropdownItem>
  <DropdownItem class="flex flex-row gap-2">
    <CalendarDays size="20" />
    <span class="grow">Training</span>
    <SquareArrowOutUpRight size="20" class="ml-4" />
  </DropdownItem>
  <DropdownItem class="flex flex-row gap-2">
    <LayoutList size="20" />
    <span class="grow">Changelog</span>
    <SquareArrowOutUpRight size="20" class="ml-4" />
  </DropdownItem>

  <DropdownDivider />

  <DropdownItem
    class="flex flex-row gap-2"
    href="https://concourse-ci.org/"
    target="_blank"
  >
    <Concourse class="w-5 h-5" />
    <span class="grow">Concourse CI Open Source</span>
    <SquareArrowOutUpRight size="20" class="ml-4" />
  </DropdownItem>
</Dropdown>

<SubmitFeedbackModal
  bind:open={feedbackModalOpen}
  outsideclose={true}
  email={rich_user?.email}
/>
