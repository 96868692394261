---
title: "Component Test"
description: "Component Test"
created: 2025-01-01
updated: 2025-01-01
toc_depth: 0
---

<script lang="ts">
    import { selected_org } from "$lib/stores/selected_org"
    import { Note, Tip, Important, Warning, Caution } from "$lib/components/alerts"
</script>

# Alerts

Note that markdown can't be included in an alert block

<Note>A Note</Note>

<Tip>A Tip</Tip>

<Important>
  Important Stuff
</Important>

<Warning>A Warning</Warning>

<Caution>A Caution</Caution>

# Dynamic Variables

Your organisation is "{$selected_org.name}"

# Other markdown Stuff

> This is a block quote

| Header 1    | Header 2    |
| ----------- | ----------- |
| Github      | Markdown    |
| Flavoured   | Table       |

Some JSON code

```json
{
  "firstName": "John",
  "lastName": "Smith",
  "age": 25
}
```

Some Java code

```java
class Main {

  public static void main(String[] args) {
    
    int first = 10;
    int second = 20;

    // add two numbers
    int sum = first + second;
    System.out.println(first + " + " + second + " = "  + sum);
  }
}
```