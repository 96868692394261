---
title: "Data Processing Agreement"
description: "Data Processing Agreement"
created: 2025-01-01
updated: 2025-01-01
footer_region: Compliance
rank: 3
show_toc: true
---

# Data Processing Agreement

**Last updated:** December 10, 2024

Download a [signed copy](todo) (including the full text of the SCCs, UK Addendum, and Sub-Processors). Be sure to send a
signed copy back to us at privacy@centralci.com.

This CentralCI Data Processing Agreement and its Annexes (“DPA”) reflects the parties’ agreement with respect
to the Processing of Personal Data by us on behalf of you in connection with providing the CentralCI Products, Platform
and Services (“Services”) under the CentralCI Terms of Services or any applicable Master Service Agreement entered into
between you and us (whichever is applicable, referred to as the “Agreement”).

This DPA is supplemental to, and forms an integral part of, the Agreement and is effective upon its incorporation
into the Agreement, which may be specified in the Agreement, an Order Form or an executed amendment to the Agreement.
In case of any conflict or inconsistency with the terms of the Agreement, this DPA will take precedence over the terms
of the Agreement to the extent of such conflict or inconsistency.

Terms not otherwise defined in this DPA will have the meaning as set forth in the applicable Agreement.

## 1. Definitions

“California Personal Information” means Personal Data that is subject to the protection of the CCPA.

“CCPA” means California Civil Code Sec. 1798.100 et seq. (also known as the California Consumer Privacy Act of 2018, as
amended by the California Privacy Rights Act of 2020 or “CPRA”).

“Consumer”, “Business”, “Sell”, “Service Provider”, and “Share” will have the meanings given to them in the CCPA.

“Controller” means the natural or legal person, public authority, agency or other body which, alone or jointly with
others, determines the purposes and means of the Processing of Personal Data.

“Customer Data” means all the personal data processed by CentralCI as a Processor on behalf of Customer as a Controller
in the course of providing the Services. Customer Data includes all Personal Data that Customer transfers to CentralCI
in connection with its use of the Services.

“Data Privacy Framework” means the EU-U.S. Data Privacy Framework, the Swiss-U.S. Data Privacy Framework and the UK
Extension to the EU-U.S. Data Privacy Framework self-certification programs (as applicable) operated by the U.S.
Department of Commerce; as may be amended, superseded or replaced.

“Data Privacy Framework Principles” means the Principles and Supplemental Principles contained in the relevant Data
Privacy Framework; as may be amended, superseded or replaced.

“Data Protection Laws” means all applicable worldwide legislation relating to data protection and privacy which applies
to the respective party in the role of Processing Personal Data in question under the Agreement, including without
limitation European Data Protection Laws, the CCPA and other applicable U.S. federal and state privacy laws; with
regards to CentralCI, Data Protection Laws exclude laws governing Sensitive Information, as defined in the Agreement.

“Data Subject” means the individual to whom Personal Data relates.

“Europe” means the European Union, the European Economic Area and/or their member states, Switzerland and the United
Kingdom.

“European Data” means Personal Data that is subject to the protection of European Data Protection Laws.

“European Data Protection Laws” means data protection laws applicable in Europe, including: (i) Regulation 2016/679 of
the European Parliament and of the Council on the protection of natural persons with regard to the processing of
personal data and on the free movement of such data (General Data Protection Regulation) (“GDPR”); (ii) Directive
2002/58/EC concerning the processing of personal data and the protection of privacy in the electronic communications
sector; and (iii) applicable national implementations of (i) and (ii); or (iii) GDPR as it forms parts of the United
Kingdom domestic law by virtue of Section 3 of the European Union (Withdrawal) Act 2018 (“UK GDPR”); and (iv) Swiss
Federal Data Protection Act and its Ordinance (“Swiss DPA”); in each case, as may be amended, superseded or replaced.

“Instructions” means the written, documented instructions issued by a Controller to a Processor, and directing the same
to perform a specific or general action with regard to Personal Data (including, but not limited to, depersonalizing,
blocking, deletion, making available).

“Permitted Affiliates” means any of your Affiliates that (i) are permitted to use the Services pursuant to the
Agreement, but have not signed their own separate agreement with us and are not a “Customer” as defined under the
Agreement, (ii) qualify as a Controller of Personal Data Processed by us, and (iii) are subject to European Data
Protection Laws.

“Personal Data” means any information relating to an identified or identifiable individual where (i) such information is
contained within Customer Data; and (ii) is protected similarly as personal data, personal information, or personally
identifiable information under applicable Data Protection Laws.

“Personal Data Breach” means a breach of security leading to the accidental or unlawful destruction, loss, alteration,
unauthorized disclosure of, or access to, Personal Data transmitted, stored or otherwise Processed by us and/or our
Sub-Processors in connection with the provision of the Services. “Personal Data Breach” will not include unsuccessful
attempts or activities that do not compromise the security of Personal Data, including unsuccessful log-in attempts,
pings, port scans, denial of service attacks, and other network attacks on firewalls or networked systems.

“Processing” means any operation or set of operations which is performed on Personal Data, encompassing the collection,
recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by
transmission, dissemination or otherwise making available, alignment or combination, restriction or erasure of Personal
Data. The terms “Process”, “Processes” and “Processed” will be construed accordingly.

“Processor” means a natural or legal person, public authority, agency or other body which Processes Personal Data on
behalf of the Controller.

“Standard Contractual Clauses” means the standard contractual clauses annexed to the European Commission’s Decision (EU)
2021/914 of 4 June 2021 currently found at https://eur-lex.europa.eu/eli/dec_impl/2021/914, as may be amended,
superseded or replaced.

“Sub-Processor” means any Processor engaged by us to assist in fulfilling our obligations with respect to the provision
of the Services under the Agreement. Sub-Processors may include third parties but will exclude any CentralCI employee or
consultant.

“UK Addendum” means the International Data Transfer Addendum issued by the UK Information Commissioner under section
119A(1) of the Data Protection Act 2018 currently found
at https://ico.org.uk/media/for-organisations/documents/4019539/international-data-transfer-addendum.pdf, as may be
amended, superseded, or replaced.

## 2. Customer Responsibilities

a. Compliance with Laws. Within the scope of the Agreement and in its use of the services, you will be responsible for
complying with all requirements that apply to it under applicable Data Protection Laws with respect to its Processing of
Personal Data and the Instructions it issues to us.

In particular but without prejudice to the generality of the foregoing, you acknowledge and agree that you will be
solely responsible for: (i) the accuracy, quality, and legality of Customer Data and the means by which you acquired
Personal Data; (ii) complying with all necessary transparency and lawfulness requirements under applicable Data
Protection Laws for the collection and use of the Personal Data, including obtaining any necessary consents and
authorizations (particularly for use by Customer for marketing purposes); (iii) ensuring you have the right to transfer,
or provide access to, the Personal Data to us for Processing in accordance with the terms of the Agreement (including
this DPA); (iv) ensuring that your Instructions to us regarding the Processing of Personal Data comply with applicable
laws, including Data Protection Laws. You will inform us without undue delay if you are not able to comply with your
responsibilities under this ‘Compliance with Laws’ section or applicable Data Protection Laws.

b. Controller Instructions. The parties agree that the Agreement (including this DPA), together with your use of the
Services in accordance with the Agreement, constitute your complete Instructions to us in relation to the Processing of
Personal Data, so long as you may provide additional instructions during the subscription term that are consistent with
the Agreement, the nature and lawful use of the Services.

c. Security. You are responsible for independently determining whether the data security provided for in the Services
adequately meets your obligations under applicable Data Protection Laws. You are also responsible for your secure use of
the Services, including protecting the security of Personal Data in transit to and from the Services (including to
securely backup or encrypt any such Personal Data).

## 3. CentralCI Obligations

a. Compliance with Instructions. We will only Process Personal Data for the purposes described in this DPA or as
otherwise agreed within the scope of your lawful Instructions, except where and to the extent otherwise required by
applicable law. We are not responsible for compliance with any Data Protection Laws applicable to you or your industry
that are not generally applicable to us.

b. Conflict of Laws. If we become aware that we cannot Process Personal Data in accordance with your Instructions due to
a legal requirement under any applicable law, we will (i) as soon as reasonably practicable notify you of that legal
requirement to the extent permitted by the applicable law; and (ii) where necessary, cease all Processing (other than
merely storing and maintaining the security of the affected Personal Data) until such time as you issue new Instructions
with which we are able to comply. If this provision is invoked, we will not be liable to you under the Agreement for any
failure to provide the Services until such time as you issue new lawful Instructions with regard to the Processing.

c. Security. We will implement and maintain appropriate technical and organizational measures to protect Personal Data
from Personal Data Breaches, as described under Annex 2 to this DPA (“Security Measures”). Notwithstanding any provision
to the contrary, we may modify or update the Security Measures at our discretion provided that such modification or
update does not result in a material degradation in the protection offered by the Security Measures.

d. Confidentiality. We will ensure that any personnel whom we authorize to Process Personal Data on our behalf is
subject to appropriate confidentiality obligations (whether a contractual or statutory duty) with respect to that
Personal Data.

e. Personal Data Breaches. We will notify you without undue delay after we become aware of any Personal Data Breach and
will provide timely information relating to the Personal Data Breach as it becomes known or reasonably requested by you.
At your request, we will promptly provide you with such reasonable assistance as necessary to enable you to notify
relevant Personal Data Breaches to competent authorities and/or affected Data Subjects, if you are required to do so
under Data Protection Laws.

f. Deletion or Return of Personal Data. We will delete or return all Customer Data, including Personal Data (including
copies thereof) Processed pursuant to this DPA, on termination or expiration of your account or Term (whichever takes
place first) in accordance with the procedures set out in the Agreement, except where we are required by applicable law
to retain some or all of the Customer Data, or where we have archived Customer Data on back-up systems, which data we
will securely isolate and protect from any further Processing and delete in accordance with our deletion practices. You
may request the deletion of your CentralCI account after expiration or termination of your subscription by sending a
request to privacy@centralci.com.

You are solely responsible for exporting your Projects and Content from the Platform before the deactivation/deletion of
your Account for any reason. We strongly recommend retrieving your Customer Data prior to the end of your Term by
following the instructions on the Platform.

## 4. Sub-Processors

We have currently appointed, as Sub-Processors, the third parties listed in Annex 3 to this DPA. You may subscribe to
receive notifications by email if we add or replace any Sub-Processors by completing the form available at here.

We will give you the opportunity to object to the engagement of new Sub-Processors on reasonable grounds relating to the
protection of Personal Data within 30 days of notifying you. If you do notify us of such an objection, the parties will
discuss your concerns in good faith with a view to achieving a commercially reasonable resolution. If no such resolution
can be reached, we will, at our sole discretion, either not appoint the new Sub-Processor, or permit you to suspend or
terminate the affected Services in accordance with the termination provisions of the Agreement without liability to
either party (but without prejudice to any fees incurred by you prior to suspension or termination).

Where we engage Sub-Processors, we will impose data protection terms on the Sub-Processors that provide at least the
same level of protection for Personal Data as those in this DPA, to the extent applicable to the nature of the services
provided by such Sub-Processors. We will remain responsible for each Sub-Processor’s compliance with the obligations of
this DPA and for any acts or omissions of such Sub-Processor that cause us to breach any of its obligations under this
DPA.

## 5. Data Transfers

You acknowledge and agree that we may access and Process Personal Data on a global basis as necessary to provide the
Services in accordance with the Agreement, and in particular that Personal Data may be transferred to and Processed by
CentralCI, Inc. in the United States and to other jurisdictions where CentralCI and Sub-Processors have operations.
Wherever Personal Data is transferred outside its country of origin, each party will ensure such transfers are made in
compliance with the requirements of Data Protection Laws.

## 6. Demonstration of Compliance

We will make information reasonably necessary to demonstrate compliance with this DPA available to you and allow for and
contribute to audits, including inspections conducted by you or your auditor in order to assess compliance with this
DPA, where required by applicable law. You acknowledge and agree that you will exercise your audit rights under this DPA
by instructing us to comply with the audit measures described in this ‘Demonstration of Compliance’ section. You
acknowledge that the CentralCI Services are hosted by our hosting Sub-Processors who maintain independently validated
security programs and that our systems are audited annually as part of SOC 2 compliance and regularly tested by
independent third party penetration testing firms. Upon request, we will supply (on a confidential basis) our SOC 2
report and summary copies of our penetration testing report(s) to you so that you can verify our compliance with this
DPA.

## 7. Additional Provisions for European Data

a. Scope. This ‘Additional Provisions for European Data’ section will apply only with respect to European Data.

b. Roles of the Parties. When Processing European Data in accordance with your Instructions, the parties acknowledge and
agree that you are the Controller of European Data and we are the Processor.

c. Instructions. If we believe that your Instruction infringes European Data Protection Laws (where applicable), we will
inform you without delay.

d. Data Protection Impact Assessments and Consultation with Supervisory Authorities. To the extent that the required
information is reasonably available to us, and you do not otherwise have access to the required information, we will
provide reasonable assistance to you with any data protection impact assessments and prior consultations with
supervisory authorities  (for example, the French Data Protection Agency (CNIL), the Berlin Data Protection Authority (
BlnBDI) and the UK Information Commissioner’s Office (ICO)) or other competent data privacy authorities to the extent
required by European Data Protection Laws.

f. Transfer Mechanisms for Data Transfers.

(A) CentralCI will not transfer European Data to any country or recipient not recognized as providing an adequate level
of protection for Personal Data (within the meaning of applicable European Data Protection Laws), unless it first takes
all such measures as are necessary to ensure the transfer is in compliance with applicable European Data Protection
Laws. Such measures may include (without limitation) (i) transferring such data to a recipient that is covered by a
suitable framework or other legally adequate transfer mechanism recognized by the relevant authorities or courts as
providing an adequate level of protection for Personal Data, including the Data Privacy Framework; (ii) to a recipient
that has achieved binding corporate rules authorization in accordance with European Data Protection Laws; or (iii) to a
recipient that has executed the Standard Contractual Clauses in each case as adopted or approved in accordance with
applicable European Data Protection Laws.

(B) You acknowledge that in connection with the performance of the CentralCI Services, CentralCI Inc. is a recipient of
European Data in the United States. To the extent that CentralCI Inc. receives European Data in the United States,
CentralCI Inc. will comply with the following:

(1) Data Privacy Framework. CentralCI Inc. will use the Data Privacy Framework to lawfully receive European Data in the
United States and ensure that it provides at least the same level of protection to such European Data as is required by
the Data Privacy Framework Principles and will let you know if it is unable to comply with this requirement.

(2) Standard Contractual Clauses. If European Data Protection Laws require that appropriate safeguards are put in
place (for example, if the Data Privacy Framework does not cover the transfer to CentralCI Inc. and/or the Data Privacy
Framework is invalidated), the Standard Contractual Clauses will be incorporated by reference and form part of the
Agreement as follows:

(a) In relation to European Data that is subject to the GDPR (i) Customer is the “data exporter” and CentralCI, Inc. is
the “data importer”; (ii) the Module Two terms apply to the extent the Customer is a Controller of European Data and the
Module Three terms apply to the extent the Customer is a Processor of European Data; (iii) in Clause 7, the optional
docking clause applies; (iv) in Clause 9, Option 2 applies and changes to Sub-Processors will be notified in accordance
with the ‘Sub-Processors’ section of this DPA; (v) in Clause 11, the optional language is deleted; (vi) in Clauses 17
and 18, the parties agree that the governing law and forum for disputes for the Standard Contractual Clauses will be
determined in accordance with the ‘Contracting Entity; Applicable Law; Notice’ section of the Jurisdiction Specific
Terms or, if such section does not specify an EU Member State, the Republic of Ireland (without reference to conflicts
of law principles); (vii) the Annexes of the Standard Contractual Clauses will be deemed completed with the information
set out in the Annexes of this DPA; (viii) the supervisory authority that will act as competent supervisory authority
will be determined in accordance with GDPR; and (ix) if and to the extent the Standard Contractual Clauses conflict with
any provision of this DPA the Standard Contractual Clauses will prevail to the extent of such conflict.

(b) In relation to European Data that is subject to the UK GDPR, the Standard Contractual Clauses will apply in
accordance with sub-section (a) and the following modifications (i) the Standard Contractual Clauses will be modified
and interpreted in accordance with the UK Addendum, which will be incorporated by reference and form an integral part of
the Agreement; (ii) Tables 1, 2 and 3 of the UK Addendum will be deemed completed with the information set out in the
Annexes of this DPA and Table 4 will be deemed completed by selecting “neither party”; and (iii) any conflict between
the terms of the Standard Contractual Clauses and the UK Addendum will be resolved in accordance with Section 10 and
Section 11 of the UK Addendum.

(c) In relation to European Data that is subject to the Swiss DPA, the Standard Contractual Clauses will apply in
accordance with sub-section (a) and the following modifications (i) references to “Regulation (EU) 2016/679” will be
interpreted as references to the Swiss DPA; (ii) references to “EU”, “Union” and “Member State law” will be interpreted
as references to Swiss law; and (iii) references to the “competent supervisory authority” and “competent courts” will be
replaced with the “the Swiss Federal Data Protection and Information Commissioner ” and the “relevant courts in
Switzerland”.

(d) You agree that by complying with our obligations under the ‘Sub-Processors’ section of this DPA, CentralCI Inc.
fulfills its obligations under Section 9 of the Standard Contractual Clauses. For the purposes of Clause 9(c) of the
Standard Contractual Clauses, you acknowledge that we may be restricted from disclosing Sub-Processor agreements but we
will use reasonable efforts to require any Sub-Processor we appoint to permit it to disclose the Sub-Processor agreement
to you and will provide (on a confidential basis) all information we reasonably can. You also acknowledge and agree that
you will exercise your audit rights under Clause 8.9 of the Standard Contractual Clauses by instructing us to comply
with the measures described in the ‘Demonstration of Compliance’ section of this DPA.

(e) If CentralCI cannot comply with its obligations under the Standard Contractual Clauses or is in breach of any
warranties under the Standard Contractual Clauses or UK Addendum (as applicable) for any reason, and you intend to
suspend the transfer of European Data to CentralCI or terminate the Standard Contractual Clauses, or UK Addendum, you
agree to provide us with reasonable notice to enable us to cure such non-compliance and reasonably cooperate with us to
identify what additional safeguards, if any, may be implemented to remedy such non-compliance. If we have not or cannot
cure the non-compliance, you may suspend or terminate the affected part of the CentralCI Services in accordance with the
Agreement without liability to either party (but without prejudice to any fees you have incurred prior to such
suspension or termination).

(f) Alternative Transfer Mechanism. In the event that CentralCI is required to adopt an alternative transfer mechanism
for European Data, in addition to or other than the mechanisms described in sub-section (b) above, such alternative
transfer mechanism will apply automatically instead of the mechanisms described in this DPA (but only to the extent such
alternative transfer mechanism complies with European Data Protection Laws), and you agree to execute such other
documents or take such action as may be reasonably necessary to give legal effect such alternative transfer mechanism.

## 8. Additional Provisions for California Personal Information

a. Scope. The ‘Additional Provisions for California Personal Information’ section of the DPA will apply only with
respect to California Personal Information.

b. Roles of the Parties. When processing California Personal Information in accordance with your Instructions, the
parties acknowledge and agree that you are a Business and we are a Service Provider for the purposes of the CCPA.

c. Responsibilities. We certify that we will Process California Personal Information as a Service Provider strictly for
the purpose of performing the Services under the Agreement  (the “Business Purpose”) or as otherwise permitted by the
CCPA, including as described in the ‘Usage Data’ section of our Privacy Policy. Further, we certify we i) will not Sell
or Share California Personal Information; (ii) will not Process California Personal Information outside the direct
business relationship between the parties, unless required by applicable law; and (iii) will not combine the California
Personal Information included in Customer Data with personal information that we collect or receive from another
source (other than information we receive from another source in connection with our obligations as a Service Provider
under the Agreement).

d. Compliance. We will (i) comply with obligations applicable to us as a Service Provider under the CCPA and (ii)
provide California Personal Information with the same level of privacy protection as is required by the CCPA. We will
notify you if we make a determination that we can no longer meet our obligations as a Service Provider under the CCPA.

e. CCPA Audits. You will have the right to take reasonable and appropriate steps to help ensure that we use California
Personal Information in a manner consistent with Customer’s obligations under the CCPA. Upon notice, you will have the
right to take reasonable and appropriate steps in accordance with the Agreement to stop and remediate unauthorized use
of California Personal Information.

f. Not a Sale. The parties acknowledge and agree that the disclosure of California Personal Information by the Customer
to CentralCI does not form part of any monetary or other valuable consideration exchanged between the parties.

## 9. General Provisions

a. Amendments. Notwithstanding anything else to the contrary in the Agreement and without prejudice to the ‘Compliance
with “Instructions” or “Security” sections of this DPA,

We reserve the right to make any updates and changes to this DPA. When changes are posted to this DPA, the “Last
Updated” date at the top will be revised.

If there are material changes to this DPA, we will notify you either by prominently posting a notice of such changes
prior to implementing the change or by directly sending you an email notification. We encourage you to periodically
review this DPA to remain informed of how CentralCI processes and protects your Personal Data.

b. Severability. If any individual provisions of this DPA are determined to be invalid or unenforceable, the validity
and enforceability of the other provisions of this DPA will not be affected.

c. Limitation of Liability. Each party and each of their Affiliates’ liability, taken in aggregate, arising out of or
related to this DPA (including any other DPAs between the parties) and the Standard Contractual Clauses, where
applicable, whether in contract, tort or under any other theory of liability, will be subject to the limitations and
exclusions of liability set out in the ‘Limitation of Liability’ section of the Agreement and any reference in such
section to the liability of a party means aggregate liability of that party and all of its Affiliates under the
Agreement (including this DPA). In no event will either party’s liability be limited with respect to any individual’s
data protection rights under this DPA (including any other DPAs between the parties and the Standard Contractual
Clauses, where applicable) or otherwise.

d. Governing Law. This Addendum will be governed by and construed in accordance with governing law and jurisdiction
provisions in the Agreement unless required otherwise by Data Protection Law.

## 10. Parties to this DPA

a. Permitted Affiliates. By signing the Agreement, you enter into this DPA (including, where applicable, the Standard
Contractual Clauses) on behalf of yourself and in the name and on behalf of your Permitted Affiliates. For the purposes
of this DPA only, and except where indicated otherwise, the terms “Customer”, “you” and “your” will include you and such
Permitted Affiliates.

b. Authorization. The legal entity agreeing to this DPA as Customer represents that it is authorized to agree to and
enter into this DPA for and on behalf of itself and, as applicable, each of its Permitted Affiliates.

c. Remedies. The parties agree that (i) solely the Customer entity that is the contracting party to the Agreement will
exercise any right or seek any remedy any Permitted Affiliate may have under this DPA on behalf of its Affiliates, and (
ii) the Customer entity that is the contracting party to the Agreement will exercise any such rights under this DPA not
separately for each Permitted Affiliate individually but in a combined manner for itself and all of its Permitted
Affiliates together. The Customer entity that is the contracting entity is responsible for coordinating all
Instructions, authorizations and communications with us under the DPA and will be entitled to make and receive any
communications related to this DPA on behalf of its Permitted Affiliates.

d. Other rights. The parties agree that you will, when reviewing our compliance with this DPA pursuant to the
‘Demonstration of Compliance’ section, take all reasonable measures to limit any impact on us and our Affiliates by
combining several audit requests carried out on behalf of the Customer entity that is the contracting party to the
Agreement and all of its Permitted Affiliates in one single audit.

## Annex 1 – Details of Processing
A. List of Parties

Data exporter:

Name: The Customer, as defined in the Order Form or Customer’s CentralCI Account (on behalf of itself and Permitted
Affiliates)

Address: The Customer’s address, as set out in the Order Form or Customer’s CentralCI Account

Contact person’s name, position and contact details: The Customer’s contact details, as set out in the Order Form and/or
as set out in the Customer’s CentralCI Account

Activities relevant to the data transferred under these Clauses: Processing of Personal Data in connection with
Customer’s use of the CentralCI Products under the Agreement

Role (controller/processor): Controller

Data importer:

Name: CentralCI Inc.

Registered Address: 209 Orange Street, City of Wilmington, County of New Castle, Delaware 19801

Address for correspondence: 2261 Market Street STE 22601, San Francisco, CA, 94114

Contact person’s name, position and contact details: Data Protection Officer, privacy@centralci.com

Activities relevant to the data transferred under these Clauses: Processing of Personal Data in connection with
Customer’s use of the Services under the Agreement

Role (controller/processor): Processor

B. Description of Transfer

Categories of Data Subjects whose Personal Data is Transferred

You may submit Personal Data in the course of using the Services, the extent of which is determined and controlled by
you in your sole discretion, and which may include, but is not limited to Personal Data relating to the following
categories of Data Subjects:

Your Contacts and other end users, including your employees, contractors, collaborators, customers, prospects,
suppliers, and subcontractors. Data Subjects may also include individuals attempting to communicate with or transfer
Personal Data to your end users.

C. Categories of Personal Data Transferred

You may submit Personal Data to the Services, the extent of which is determined and controlled by you in your sole
discretion and which may include but is not limited to the following categories of Personal Data:

- Email address, full name, IP address of Authorized Users;
- Customer legal name and registered address (for legal entities);
- Position (role in the team) of Authorized Users;
- Phone number of the Customer’s representatives.

If the Customer makes payments or conducts payment transactions related to the Services through a third- party website
or application, CentralCI will receive Customer’s transaction information in CentralCI’s third- party payment processing
software along with partial details of the bank account information. The information that CentralCI will be able to
verify will include payment method information, such as:

- Cardholder name;
- Email address;
- Unique customer identifier;
- Order ID;
- Limited bank account details; or partial payment card details (last four numbers and card type);
- Card expiration date;
- Date/time/total amount of transaction;
- Location
- Billing address
- Residential address

Different payment methods may require the collection of various categories of information. The payment method
information that CentralCI collects will depend upon the payment method that Customer chooses to use from the available
payment methods offered to Customer.

D. Sensitive Information transferred and applied restrictions or safeguards

The parties do not anticipate the transfer of Sensitive Information.

E. Frequency of the transfer

Continuous

F. Nature of the Processing

Personal Data will be Processed in accordance with the Agreement (including this DPA) and may be subject to the
following Processing activities:

1. Storage and other Processing necessary to provide, maintain, and improve the Services provided to you; and/or

2. Disclosure in accordance with the Agreement (including this DPA) and/or as compelled by applicable laws.

G. Purpose of the transfer and further processing

We will Process Personal Data as necessary to provide the Services pursuant to the Agreement.

H. Period for which Personal Data will be retained

Subject to the ‘Deletion or Return of Personal Data’ section of this DPA, we will Process Personal Data for the duration
of the Agreement, unless otherwise agreed in writing.

## Annex 2 – Security Measures

We currently observe the Security Measures described in this Annex 2. All capitalized terms not otherwise defined herein
will have the meanings as set forth in the Agreement.

a) Access Control

i)  Preventing Unauthorized Product Access

Outsourced processing: We host our Service on an outsourced cloud infrastructure provider, according to a shared
responsibility model. Additionally, we maintain contractual relationships with vendors in order to provide the Services
in accordance with our DPA. We rely on contractual agreements, privacy policies, and vendor compliance programs in order
to protect data processed or stored by these vendors.

Physical and environmental security: We host our product infrastructure with multi-tenant, outsourced infrastructure
providers. We do not own or maintain hardware located at the outsourced infrastructure providers’ data centers.
Production servers and client-facing applications are logically and physically secured from our internal corporate
information systems. The physical and environmental security controls are audited for SOC 2 Type 2.

Authentication: We implement a uniform password policy for our customer products. Customers who interact with the
products via the user interface must authenticate before accessing Customer Data.

Authorization: Customer Data is stored in multi-tenant storage systems accessible to Customers via only application user
interfaces and application programming interfaces. Customers are not allowed direct access to the underlying application
infrastructure. The authorization model in each of our products is designed to ensure that only the appropriately
assigned individuals can access relevant features, views, and customization options. Authorization to data sets is
performed through validating the user’s permissions against the attributes associated with each data set.

Application Programming Interface (API) access: Public product APIs can be accessed using an API key or through Oauth
authorization.

ii) Preventing Unauthorized Use

We implement industry standard access controls and detection capabilities for the internal networks that support its
products.

Access controls: Network access control mechanisms are designed to prevent network traffic using unauthorized protocols
from reaching the product infrastructure. The technical measures implemented differ between infrastructure providers and
include Virtual Private Cloud (VPC) implementations, security group assignment, and traditional firewall rules.

Intrusion detection and prevention: We implement a Web Application Firewall (WAF) solution to protect hosted customer
websites and other internet-accessible applications. The WAF is designed to identify and prevent attacks against
publicly available network services.

Static code analysis: Code stored in our source code repositories is checked for best practices and identifiable
software flaws using automated tooling.

Penetration testing: We maintain relationships with industry-recognized penetration testing service providers for
penetration testing of the CentralCI web application, API, and proximity and authentications flows at least annually.
The intent of these penetration tests is to identify security vulnerabilities and mitigate the risk and business impact
they pose to the in-scope systems.

iii) Limitations of Privilege & Authorization Requirements

Product access: A subset of our employees have access to the products and to customer data via controlled interfaces.
The intent of providing access to a subset of employees is to provide effective customer support, product development
and research, to troubleshoot potential problems, to detect and respond to security incidents and implement data
security. Access is enabled through “just in time” (JITA) requests for access; all such requests are logged. Employees
are granted access by role, and reviews of high risk privilege grants are initiated as needed. Administrative or high
risk access permissions are reviewed at least annually.

Reference checks: Where permitted by applicable law, CentralCI employees undergo reference checks. All CentralCI
employees are required to conduct themselves in a manner consistent with company guidelines, non-disclosure
requirements, and ethical standards.

b) Transmission Control

In-transit: We require HTTPS encryption (also referred to as SSL or TLS)  on all login interfaces. Our HTTPS
implementation uses industry standard algorithms and certificates.

At-rest: We store user passwords following policies that follow industry standard practices for security. We have
implemented technologies to ensure that stored data is encrypted at rest.

c) Input Control

Detection: We designed our infrastructure to log extensive information about the system behavior, traffic received,
system authentication, and other application requests. Internal systems aggregate log data and alert appropriate
employees of malicious, unintended, or anomalous activities. Our personnel, including security, operations, and support
personnel, are responsive to known incidents.

Response and tracking: We maintain a record of known security incidents that includes description, dates and times of
relevant activities, and incident disposition. Suspected and confirmed security incidents are investigated by security,
operations, or support personnel; and appropriate resolution steps are identified and documented. For any confirmed
incidents, we will take appropriate steps to minimize product and Customer damage or unauthorized disclosure.
Notification to you will be in accordance with the terms of the Agreement.

d) Availability Control

Infrastructure availability: The infrastructure providers use commercially reasonable efforts to ensure a minimum of
99.5% uptime. The providers maintain a minimum of N+1 redundancy to power, network, and heating, ventilation and air
conditioning (HVAC) services.

Fault tolerance: Backup and replication strategies are designed to ensure redundancy and fail-over protections during a
significant processing failure. Customer Data is stored in cold storage (S3 for AWS).

Online replicas and backups: Where feasible, production databases are designed to replicate data between no less than 1
primary and 1 secondary database. All databases are backed up and maintained using at least industry standard methods.

Disaster Recovery Plans: We maintain and regularly test disaster recovery plans to help ensure availability of
information following interruption to, or failure of, critical business processes.

Our products are designed to ensure redundancy and seamless failover. The server instances that support the products are
also architected with a goal to prevent single points of failure. This design assists our operations in maintaining and
updating the product applications and backend while limiting downtime.

## Annex 3 – Sub-Processors

To help CentralCI deliver the Service, we engage Sub-Processors to assist with our data processing activities. A list of
our Sub-Processors and our purpose for engaging them is located on our [CentralCI Sub-Processors Page](sub-processors) which is
incorporated into this DPA.