<script lang="ts">
  import { enhance } from "$app/forms"
  import {
    Button,
    Modal,
    Label,
    Input,
    Checkbox,
    Textarea,
    Helper,
  } from "flowbite-svelte"

  import { Star } from "lucide-svelte"

  type FormData =
    | undefined
    | {
        success?: boolean
        errors: Record<string, string>
      }

  let {
    open = $bindable(),
    outsideclose,
    email,
  }: {
    open: boolean
    outsideclose: boolean
    email?: string
  } = $props()

  let form: undefined | FormData = $state(undefined)
  let rating: number = $state(0)

  function* range(start: number, end: number): Generator<number> {
    for (let i = start; i <= end; i++) yield i
  }

  const submitFeedback = () => {
    return async ({ result }: { result: { data?: unknown; type: string } }) => {
      console.log({ result })
      form = result?.data as FormData
      if (result.type === "success") {
        rating = 0
        open = false
      }
    }
  }
</script>

<Modal
  bind:open
  outsideclose
  autoclose={false}
  size="sm"
  placement="center"
  title="Send Feedback"
  class="absolute drop-shadow-2xl border-2 border-white"
>
  <form
    class="flex flex-col space-y-4"
    action="/submit_feedback"
    method="POST"
    use:enhance={submitFeedback}
  >
    <p>We value your input and are committed to improving your experience.
      Please share any bugs you encounter, areas for improvement, or
      feature suggestions!</p>
    <Label class="space-y-2">
      <span>Your Email</span>
      <Input
        type="email"
        name="email"
        placeholder={email ? "" : "name@company.com"}
        value={email ?? ""}
      />
      {#if form?.errors?.email}
        <Helper class="mt-2" color="red">Please provide your email address</Helper>
      {/if}
    </Label>
    <Label class="space-y-2">
      <span>Your Feedback</span>
      <Textarea id="textarea-id" rows={4} name="feedback_text" />
      {#if form?.errors?.feedback_text}
        <Helper class="mt-2" color="red">Please tell us about your experience</Helper>
      {/if}
    </Label>
    <Label class="space-y-2">
      <span>How would you rate CentralCI so far?</span>
      <div class="flex flex-row text-amber-500">
        {#each range(1, 5) as i}
          <!-- svelte-ignore a11y_click_events_have_key_events -->
          <!-- svelte-ignore a11y_no_static_element_interactions -->
          <div onclick={() => (rating = i)}>
            {#if rating >= i}<Star fill="#f59e0b" />{:else}<Star />{/if}
          </div>
        {/each}
        <input name="rating" type="hidden" value={rating} />
      </div>
      {#if form?.errors?.rating}
        <Helper class="mt-2" color="red">Please share your rating</Helper>
      {/if}
    </Label>
    <div class="flex items-start">
      <Checkbox checked name="can_contact">
        May we contact you about your feedback?
      </Checkbox>
    </div>
    <Button class="w-full1" type="submit">Submit</Button>
    {#if form?.errors?.db}
      <Helper class="mt-2" color="red">
        {form.errors.db}
      </Helper>
    {/if}
  </form>
</Modal>
