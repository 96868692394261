---
title: "Sub-processors"
description: "List of CentralCI’s Sub-processors"
created: 2025-01-01
updated: 2025-01-01
footer_region: Compliance
rank: 3
show_toc: false
---

# Sub-processors

**Last updated:** December 11, 2024

To support the delivery of our Services, CentralCI Inc. may engage and use data processors with access to certain
Customer Data. This page provides important information about the identity, location, and role of each Sub-processor.

This Sub-Processor page is incorporated into the DPA and Terms of Service or MSA entered into between Customer
and CentralCI (whichever shall be applicable).

## Updates to this page

Due to the nature of our global business and our ongoing efforts to delight our customers, our business needs and
services providers may change from time to time. For example, we may deprecate a service provider to consolidate
and minimize our use of service providers. Similarly, we may add a service provider if we believe that doing
so will enhance our ability to deliver our Services.

You may subscribe to notifications by email if we add or replace any Sub-Processors by clicking here:

[Subscribe to updates](mailto:dpa-subscribe@centralci.com)

We will only use the information you provide on this form to process your request for notices about our
sub-processor list under our DPA and otherwise to provide you the CentralCI Services you subscribe to. For more
information on how CentralCI protects your information, check out our [Privacy Policy](privacy-policy).

CentralCI may use the following Sub-processors to deliver the Services to you:

| Vendor Name	         | Location       | Purpose                                                         |
|----------------------|----------------|-----------------------------------------------------------------|
| Amazon Web Services	 | United States  | Infrastructure service provider                                 |
| CloudFlare           | United States  | Infrastructure service provider                                 |
| Datadog              | United States  | Infrastructure service provider                                 |
| DocuSign	            | United States	 | Document Management                                             |
| Google Analytics	    | United States	 | Analytics                                                       |
| GSuite	              | United States  | Internal and External Communications Document Management System |
| HashiCorp            | United States  | Tooling                                                         |
| Microsoft Azure	     | United States  | Infrastructure service provider                                 |
| Slack                | United States  | Internal Messenger                                              |
| Stripe               | United States	 | Credit Card Processing                                          | 
| Zapier               | United States  | Integration provider                                            |

---
