import { isBrowser } from "@supabase/ssr"
import type { Session, SupabaseClient, User, AuthError } from "@supabase/supabase-js"
import type { Database } from "../dbschema/wrangled"
import { jwtDecode } from 'jwt-decode'
import { type Org } from "./stores/selected_org"

export function role_for_session(session: Session): string | undefined {
  const jwt = jwtDecode(session.access_token)
  const user_role = (jwt as {user_role?: string}).user_role
  return user_role
}

function full_name_or_fallback(full_name?: string, user?: User) {
  if (full_name) return full_name

  const userUuid = user?.id ?? null
  const userEmailOrUuid = user?.email ?? userUuid
  return userEmailOrUuid ? userEmailOrUuid.split("@", 1)[0] : "<unknown>"
}

export type RichUser = User & {
  role: string
  orgs: Org[]
  full_name: string
  avatar_url: string
}

type HelperReturn = {
  session: Session | null,
  rich_user: RichUser | null
  error?: AuthError
}

export async function enrich_user(
  supabase: SupabaseClient<Database>,
  session: Session,
  user: User
): Promise<RichUser> {
  const { data: extras_result } = await supabase
  .from("user_extras")
  .select("avatar_url, full_name")
  .limit(1)
  .single()

  const { data: orgs_result } = await supabase
    .from("org_users")
    .select("orgs(uuid, name, avatar_url)")
    .eq("user_uuid", user.id)

  const rich_user = {
    ...user,
    role: role_for_session(session) ?? "",
    orgs: orgs_result?.flatMap(d => d.orgs ? [d.orgs] : []) ?? [],
    full_name: full_name_or_fallback(extras_result?.full_name, user),
    avatar_url: extras_result?.avatar_url ?? "/blank-profile-picture.png"
  } satisfies RichUser

  return rich_user
}
export async function load_helper(
  server_session: Session | null,
  supabase: SupabaseClient<Database>,
): Promise<HelperReturn> {
  // on server populated on server by LayoutData, using authGuard hook
  let session = server_session
  if (isBrowser()) {
    // Only call getSession in browser where it's safe.
    const getSessionResponse = await supabase.auth.getSession()
    session = getSessionResponse.data.session
  }
  if (!session) {
    return {
      session: null,
      rich_user: null
    }
  }

  // https://github.com/supabase/auth-js/issues/888#issuecomment-2189298518
  if ("suppressGetSessionWarning" in supabase.auth) {
    // @ts-expect-error - suppressGetSessionWarning is not part of the official API
    supabase.auth.suppressGetSessionWarning = true
  } else {
    console.warn(
      "SupabaseAuthClient#suppressGetSessionWarning was removed. See https://github.com/supabase/auth-js/issues/888.",
    )
  }

  const {
    data: { user },
    error: userError,
  } = await supabase.auth.getUser()
  if (!user) {
    return {
      session: null,
      rich_user: null,
    }
  }
  if (userError) {
    return {
      session: null,
      rich_user: null,
      error: userError
    }
  }

  return {
    session,
    rich_user: await enrich_user(supabase, session, user)
  }
}
